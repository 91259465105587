import React from 'react';
import ApplicationsTable from "../../components/leadsTable";

const Dashboard = () => {

      return (<>
            <div className='page-head flex align-center justify-between mb-30'>
                  <h3>Dashboard</h3>
            </div>
      </>);
};

export default Dashboard;
