import React, { useCallback, useState } from 'react';
import { Outlet, useLocation } from 'react-router-dom';
import PropTypes from 'prop-types';

import Sidebar from 'components/sidebar';
import Header from 'components/header';

const DashboardLayout = () => {
  const { pathname } = useLocation();
  const [mobileMenu, setMobileMenu] = useState(false);
  const [createFolderModal, setCreateFolderModal] = useState(false);

  const openCreateFolderModal = useCallback(
    () => setCreateFolderModal(true),
    [],
  );
  const closeCreateFolderModal = useCallback(
    () => setCreateFolderModal(false),
    [],
  );

  const openMobileMenu = () => {
    setMobileMenu(true);
  };
  const closeMobileMenu = () => {
    setMobileMenu(false);
  };

  return (
    <>
      <div
        className={`dashboard-content flex ${mobileMenu ? 'menu-open' : ''}`}
      >
        <Sidebar mobileMenu={mobileMenu} closeMobileMenu={closeMobileMenu} />
        <div className='dashboard-right'>
          <Header openMobileMenu={openMobileMenu} />
          <div className='dashboard-body'>
            <Outlet context={openCreateFolderModal} />
          </div>
        </div>
      </div>
    </>
  );
};

DashboardLayout.propTypes = {
  children: PropTypes.node,
};

export default DashboardLayout;
