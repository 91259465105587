import React, {useEffect, useRef, useState} from 'react';
import Avatar from '@mui/material/Avatar';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import { format } from 'date-fns';
import Th from '../table/th';

const ApplicationsTable = ({
  // scrollValue,
  // setScrollValue,
  leads,
  // selectedLeads,
  // setSelectedLeads,
  // order,
  // setOrder,
  // setPage,
}) => {
  const containerRef = useRef(null);
  const [selectedLeads, setSelectedLeads] = useState([]);

  const handleCheckboxChange = (id) => {
    selectedLeads.includes(id)
      ? setSelectedLeads((prev) => prev.filter((item) => item !== id))
      : setSelectedLeads((prev) => [...prev, id]);
  };

  return (
    <div className='table' ref={containerRef}>
      <table>
        <thead>
          <tr>
            <Th
              // containerRef={containerRef}
              // setScrollValue={setScrollValue}
              text={'Name'}
              field={'name'}
              // setPage={setPage}
              // order={order}
              // setOrder={setOrder}
              withFiltering={false}
            />
            <Th
              // containerRef={containerRef}
              // setScrollValue={setScrollValue}
              text={'Plan'}
              field={'plan'}
              // setPage={setPage}
              // order={order}
              // setOrder={setOrder}
              withFiltering={false}
            />
            <Th
              // containerRef={containerRef}
              // setScrollValue={setScrollValue}
              text={'Expenses'}
              field={'expenses'}
              // setPage={setPage}
              // order={order}
              // setOrder={setOrder}
              withFiltering={false}
            />
            <Th
              // containerRef={containerRef}
              // setScrollValue={setScrollValue}
              text={'Usage'}
              field={'usage'}
              // setPage={setPage}
              // order={order}
              // setOrder={setOrder}
              withFiltering={false}
            />
            <Th
              // containerRef={containerRef}
              // setScrollValue={setScrollValue}
              text={'Renewal'}
              field={'renewal'}
              // setPage={setPage}
              // order={order}
              // setOrder={setOrder}
              withFiltering={false}
            />

          </tr>
        </thead>
        <tbody>
          {leads?.map((lead) => (
            <tr key={lead.id}>
              <td>
                <div className='flex align-center'>
                  <label htmlFor={lead.id}>
                    <input
                      type='checkbox'
                      id={lead.id}
                      checked={selectedLeads.includes(lead.id)}
                      onChange={() => handleCheckboxChange(lead.id)}
                    />
                    <b />
                  </label>
                  <Avatar alt={lead.name} src={require(`assets/images/${lead.logo}.png`)} sx={{marginX: 2}} />
                  {lead.name}
                </div>
              </td>
              <td>{lead.plan}</td>
              <td>{lead.expenses? `$ ${lead.expenses}`: ""}</td>
              <td>{lead.usage}</td>
              <td>{!lead.renewal? " " : format(new Date(lead.renewal), 'dd-MM-yyyy')}</td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

ApplicationsTable.propTypes = {
  leads: PropTypes.array,
  selectedLeads: PropTypes.array,
  setSelectedLeads: PropTypes.func,
  order: PropTypes.string,
  setOrder: PropTypes.func,
  setPage: PropTypes.func,
  setTargetElementRef: PropTypes.func,
  targetElementRef: PropTypes.any,
  scrollValue: PropTypes.number,
  setScrollValue: PropTypes.func,
};

export default ApplicationsTable;
