import React, { useEffect } from 'react';
import { Link, useNavigate, useLocation } from 'react-router-dom';
import BarChartIcon from '@mui/icons-material/BarChart';
import AutoAwesomeMotionIcon from '@mui/icons-material/AutoAwesomeMotion';
import CalendarMonthIcon from '@mui/icons-material/CalendarMonth';
import SettingsIcon from '@mui/icons-material/Settings';
import FeedIcon from '@mui/icons-material/Feed';
import PaidIcon from '@mui/icons-material/Paid';
import PropTypes from 'prop-types';


import { PATHS_DASHBOARD } from 'routes/paths';
import NavItem from '../navItem';

const NAV_ITEMS_LIST = [
  { text: 'Dashboard', icon: <BarChartIcon />, navPath: 'general', availableFor: 'all' },
  {
    text: 'Applications',
    icon: <AutoAwesomeMotionIcon />,
    navPath: 'applications',
    availableFor: 'all',
  },
  { text: 'Renewals', icon: <CalendarMonthIcon />, navPath: 'renewals', availableFor: 'all' },
  { text: 'Expenses', icon: <PaidIcon />, navPath: 'expenses', availableFor: 'all' },
  { text: 'Reports', icon: <FeedIcon />, navPath: 'reports', availableFor: 'all' },
  { text: 'Settings', icon: <SettingsIcon />, navPath: 'settings', availableFor: 'all' },
];

const Sidebar = ({ mobileMenu, closeMobileMenu }) => {
  const navigate = useNavigate();
  const location = useLocation();

  const activeRoute = (route) => location.pathname.includes(route);

  useEffect(() => {
  }, []);

  return (
    <div className={`sidebar ${mobileMenu ? 'open' : ''}`}>
      <div className='sidebar-head'>
        <button className='mobile-close-menu' onClick={closeMobileMenu}>
          <i className='icon-close' />
        </button>
        <Link to={PATHS_DASHBOARD.general}>
          <img src={require('assets/images/sidebar-logo.png')}/>
        </Link>
      </div>
      <div className='sidebar-body'>
        <ul className='navigation'>
          {NAV_ITEMS_LIST.map((item) => (
            <NavItem
              key={item.navPath}
              text={item.text}
              icon={item.icon}
              navPath={PATHS_DASHBOARD[item.navPath]}
              className={
                activeRoute(PATHS_DASHBOARD[item.navPath]) ? 'active' : ''
              }
            />
          ))}
        </ul>
      </div>
    </div>
  );
};

Sidebar.propTypes = {
  mobileMenu: PropTypes.any,
  closeMobileMenu: PropTypes.any,
};

export default Sidebar;
