import React, { useRef } from 'react';
import Popover from '@mui/material/Popover';
import PropTypes from 'prop-types';

const Th = ({
  setScrollValue,
  containerRef,
  text,
  value,
  field,
  setFilterValues,
  withSorting = true,
  withFiltering = true,
  order,
  setOrder,
  setPage,
}) => {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const targetRef = useRef();

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const changeFilterValue = (field, value) => {
    setPage(1);
    setFilterValues((prev) => ({ ...prev, [field]: value }));
  };

  // const orderingSwitcher = () => {
  //   setPage(1);
  //   if (containerRef?.current) {
  //     setScrollValue(containerRef.current.scrollLeft);
  //   }
  //   !order.includes(field) || order.includes('_desc')
  //     ? setOrder(`${field}_asc`)
  //     : setOrder(`${field}_desc`);
  // };

  const open = Boolean(anchorEl);
  const id = open ? 'simple-popover' : undefined;
  return (
    <th ref={targetRef}>
      <div className={`${open ? 'open' : ''}`}>
        <div className='flex align-center'>
          {withSorting && (
            <button
                className={'sort-button'}
              // className={`sort-button ${
              //   order.includes('_desc') ||
              //   (order !== `${field}_asc` && order !== `${field}_desc`)
              //     ? 'desc'
              //     : 'asc'
              // }`}
              // onClick={orderingSwitcher}
            >
              <i className={'icon-sort-descending'} />
            </button>
          )}
          {text}
          {withFiltering && (
            <button onClick={handleClick}>
              <i className='icon-settings' />
            </button>
          )}
          <Popover
            open={open}
            anchorEl={anchorEl}
            onClose={handleClose}
            className={'search-popover'}
            anchorOrigin={{
              vertical: 'bottom',
              horizontal: 'left',
            }}
          >
            <input
              type='text'
              placeholder='Search'
              value={value}
              onChange={(event) => changeFilterValue(field, event.target.value)}
            />
          </Popover>
        </div>
      </div>
    </th>
  );
};

Th.propTypes = {
  setActEl: PropTypes.func,
  text: PropTypes.string,
  value: PropTypes.string,
  setFilterValues: PropTypes.func,
  field: PropTypes.string,
  withSorting: PropTypes.bool,
  withFiltering: PropTypes.bool,
  order: PropTypes.string,
  setOrder: PropTypes.func,
  setPage: PropTypes.func,
  setScrollValue: PropTypes.func,
  containerRef: PropTypes.any,
};

export default Th;
