import React, {useEffect, useState} from 'react';

import ApplicationsTable from "../../components/leadsTable";

const Applications = () => {
    const [DATA, setData] = useState([
        {id: 1, name: "Atlassian", logo: 'atlassian', plan: 'Premium', expenses: 4805, usage: "504", renewal: "2025-05-17"},
        {id: 2, name: "Zoom", logo: 'zoom', plan: '', expenses: "", usage: "", renewal: ""},
        {id: 3, name: "Slack", logo: 'slack', plan: 'Business', expenses: 7200, usage: "330", renewal: "2024-03-31"},
        {id: 4, name: "MailChimp", logo: 'mailchimp', plan: 'Standard', expenses: 3520, usage: "410", renewal: "2025-01-12"},
        {id: 5, name: "Figma", logo: 'figma', plan: 'Enterprise', expenses: 6400, usage: "780", renewal: "2024-05-08"},
    ])

    const zoomData = (data) => {
        const newData = [...DATA];
        newData[1].plan = data?.plan_type?.charAt(0).toUpperCase() + data?.plan_type?.slice(1);
        newData[1].expenses = data?.price;
        newData[1].usage = data?.amount;
        newData[1].renewal = data?.renewal;

        setData(newData);
    }

    const getApplications = () => {
        fetch("https://errios-back.codebnb.me/api/accounts/usage/",
            {
                headers: {
                    "Content-Type": "application/json",
                    Accept: 'application/json',
                },
            }
        ).then(res => res.json())
            .then(data => zoomData(data?.results?.find(item => item.plan === "Zoom")))
            .catch(err => console.log("ERRor", err))
    };

    useEffect(() => {getApplications()},[])

    return (<>
        <div className='page-head flex align-center justify-between mb-30'>
            <h3>Applications ({DATA.length})</h3>
        </div>
        <ApplicationsTable
            leads={DATA}
            // selectedLeads={selectedLeads}
            // setSelectedLeads={setSelectedLeads}
            // order={leadsOrder}
            // setOrder={setLeadsOrder}
            // setPage={setPage}
            // scrollValue={scrollValue}
            // setScrollValue={setScrollValue}
        />
        {/*<Pagination*/}
        {/*    count={Math.ceil(data?.data?.count / pageSize)}*/}
        {/*    page={page}*/}
        {/*    onChange={(e, p) => {*/}
        {/*          setPage(p);*/}
        {/*    }}*/}
        {/*/>*/}
    </>);
};

export default Applications;
