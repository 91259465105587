import React from 'react';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';

const NavItem = ({ text, icon, className, navPath, onClick }) => {
  return (
    <li className={`navigation-item ${className}`}>
      <Link to={navPath} className='flex align-center' onClick={onClick}>
        <div style={{marginRight: "15px"}}>{icon}</div>
        {text}
      </Link>
    </li>
  );
};

NavItem.propTypes = {
  text: PropTypes.string,
  icon: PropTypes.any,
  className: PropTypes.string,
  navPath: PropTypes.string,
  onClick: PropTypes.func,
};

export default NavItem;
