import React, { useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

import { PATHS_DASHBOARD } from 'routes/paths';
import PropTypes from 'prop-types';

const Header = ({ openMobileMenu }) => {
  const navigate = useNavigate();
  const location = useLocation();

  const showBackButton =
    (location.pathname.split('/')[3] === 'folders' &&
      location.pathname.split('/')[4]) ||
    (location.pathname.split('/')[3] === 'user' &&
      location.pathname.split('/')[4]);

  const showActionFields = [
    PATHS_DASHBOARD.general,
    PATHS_DASHBOARD.applications,
  ].some((path) => path === location.pathname);

  const onSearchFieldChange = (e) => {
  };

  return (
    <header className='flex justify-between align-center'>
      <div className='head-left flex align-center'>
        <button className='mobile-menu-button' onClick={openMobileMenu}>
          <i className='icon-menu' />
        </button>
        {showBackButton ? (
          <button
            className='back-button'
            onClick={() => {
              navigate(-1);
            }}
          >
            <i className='icon-back-arrow' />
          </button>
        ) : (
          <></>
        )}
        {showActionFields && (
          <div className='search-field'>
            <input
              type='text'
              placeholder={`Search`}
              value={''}
              onChange={onSearchFieldChange}
            />
          </div>
        )}
      </div>
    </header>
  );
};

Header.propTypes = {
  openMobileMenu: PropTypes.any,
};

export default Header;
