import {Navigate, useRoutes} from 'react-router-dom';
import {PATHS_DASHBOARD} from "./paths";
import DashboardLayout from "../layouts/DashboardLayout";
import Dashboard from "../pages/dashboard/Dashboard";
import Applications from "../pages/dashboard/Applications";

const Router = () => {
    return useRoutes([
        {
            path: 'dashboard',
            element: <DashboardLayout />,
            children: [
                { path: '', element: <Navigate to={PATHS_DASHBOARD.general} /> },
                { path: PATHS_DASHBOARD.general, element: <Dashboard /> },
                { path: PATHS_DASHBOARD.applications, element: <Applications /> },

            ]
        },
        {path: "/", element: <Navigate to={PATHS_DASHBOARD.applications} />}
    ])
}

export default Router;



